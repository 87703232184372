import React from 'react';
import styled from 'styled-components';
import { LOGIN_ERRORS } from 'midtype-backend';
import qs from 'query-string';

import Layout from '../layout/Layout';
import Illustration from '../icons/IllustrationFingerprint';

const Styled = styled.article`
  position: relative;
  max-width: 60rem;
  margin: 12rem auto;
  text-align: center;
  padding: 0 3rem;
  h2 {
    margin: 1rem 0;
  }
`;

const NotFoundPage: React.FC<any> = props => {
  const { reason } = qs.parse(props.location.search);
  const err = Object.keys(LOGIN_ERRORS).find(
    key => LOGIN_ERRORS[key].slug === reason
  );
  return (
    <Layout>
      <Styled>
        <Illustration width="300px" height="250px" />
        <h2>Login Trouble!</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: err
              ? LOGIN_ERRORS[err].message
              : 'There was an issue logging you into this app.'
          }}
        />
      </Styled>
    </Layout>
  );
};

export default NotFoundPage;
